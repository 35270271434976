import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const Home = lazy(() => import('../pages/home'))

export const homeRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component:  Home,
})

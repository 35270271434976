import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const Bienvenida100 = lazy(() => import('../pages/bienvenida-100'))

export const bienvenida100Route = new Route({
  getParentRoute: () => rootRoute,
  path: '/bienvenida-100',
  component: Bienvenida100,
})

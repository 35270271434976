import { Suspense } from 'react'
import { hydrate, render } from 'react-dom'
import { RouterProvider } from '@tanstack/router'

import Loading from './components/loading'
import './i18n'
import { router } from './router'

const Root = () => (
  <Suspense fallback={<Loading />}>
    <RouterProvider router={router} />
  </Suspense>
)

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  if (rootElement?.hasChildNodes()) {
    hydrate(<Root />, rootElement)
  } else {
    render(<Root />, rootElement)
  }
}

import { Router } from '@tanstack/router'
import qs, { IStringifyBaseOptions } from 'qs'
import { rootRoute } from './routes/root'
import { gana500Route } from './routes/gana-500'
import { bienvenida100Route } from './routes/bienvenida-100'
import { homeRoute } from './routes/home'

export const QS_CONFIG: IStringifyBaseOptions = {
  arrayFormat: 'indices',
  encode: false,
}

const routeTree = rootRoute.addChildren([
  gana500Route,
  bienvenida100Route,
  homeRoute,
])

function customStringifier(searchObj: Record<string, unknown>) {
  return qs.stringify(searchObj, { ...QS_CONFIG, addQueryPrefix: true })
} 

function customParser(searchString: string) {
  return qs.parse(searchString, { ...QS_CONFIG, ignoreQueryPrefix: true })
}

export const router = new Router({
  routeTree,
  defaultPreload: 'intent',
  stringifySearch: customStringifier,
  parseSearch: customParser,
})

declare module '@tanstack/router' {
  interface Register {
    router: typeof router
  }
}

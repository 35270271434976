import { Outlet, RootRoute } from '@tanstack/router'
import { useEffect } from 'react'

export const rootRoute = new RootRoute({
  component: () => {
    useEffect(() => {
      document.getElementById('splash-screen')?.remove()
      document.getElementById('splash-screen-style')?.remove()
    }, [])
    return <Outlet />
  },
})

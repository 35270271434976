import { Route, lazy } from '@tanstack/router'

import { rootRoute } from './root'

const Gana500 = lazy(() => import('../pages/gana-500'))

export const gana500Route = new Route({
  getParentRoute: () => rootRoute,
  path: '/gana-500',
  component: Gana500,
})
